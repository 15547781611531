<template>
  <div class="container-page">
    <div>
      <h2 class="title-inner">Карточка операции</h2>
    </div>
    <div>
      <div class="form-group required">
        <p class="label-field">Название <span>*</span></p>
        <ComponentInput v-model="card.name" required maxLength="255" mod="rectangular" placeholder="Введите название" />
      </div>
      <div class="form-group">
        <p class="label-field">Электронное получение услуги</p>
        <ComponentInput
          v-model="card.serviceUrl"
          maxLength="255"
          mod="rectangular"
          placeholder="Введите ссылку на внешний ресурс, предоставляющий услугу"
        />
      </div>
      <hr />
      <h3 class="mb-32">Регуляторы и участники ВЭД</h3>
      <h3 class="mb-27">Регуляторы</h3>

      <CardListItems
        :listItems="regulators"
        :options="getListOrganizations"
        @add="addRegulators"
        @delete="deleteRegulators"
        @duplicates="duplicatesRegulators"
      />
      <hr />
      <h3 class="mb-27">Участники</h3>

      <CardListItems
        :listItems="participants"
        :options="getListOrganizations"
        @add="addParticipant"
        @delete="deleteParticipant"
        @duplicates="duplicatesParticipant"
      />
      <hr />

      <DocumentItem
        header="Результаты"
        buttonName="Добавить результаты"
        placeholder="Выберите результаты"
        :documents="card.stepResultDocumentIds"
        :options="getListDocuments"
        @add="addResultDocument"
        @delete="deleteResultDocument"
        @duplicates="duplicatesResultDocument"
      />

      <div class="mt-2">
        <DocumentItem
          header="Требуемые документы"
          buttonName="Добавить требуемые документы"
          placeholder="Выберите документы"
          :documents="card.stepRequiredDocumentIds"
          :options="getListDocuments"
          @add="addRequiredDocument"
          @delete="deleteRequiredDocument"
          @duplicates="duplicatesRequiredDocument"
        />

        <DocumentItem
          header="Правовые источники"
          buttonName="Добавить правовые источники"
          placeholder="Выберите источники"
          :documents="card.stepLegalSourceIds"
          :options="getListLegalSources"
          @add="addLegalSource"
          @delete="deleteLegalSource"
          @duplicates="duplicatesLegalSource"
        />

        <hr />
        <TimeAndCostForm
          v-model:duration="card.duration"
          v-model:hour="hours"
          v-model:minute="minutes"
          v-model:durationComment="card.durationComment"
          v-model:cost="card.cost"
          v-model:costComment="card.costComment"
          :isHourFormat="card.isHourFormat"
          @switchHandlerTime="switchHandTime"
        />

        <hr />
        <h3 class="mb-27">Шаги</h3>
        <ActionItems
          :actions="card.stepActions"
          columnName="Шаг"
          btnName="Добавить шаги"
          @add="addAction"
          @delete="deleteAction"
        />

        <hr />
        <h3 class="mb-27">Видео</h3>
        <ActionItems
          :actions="card.stepVideos"
          columnName="Название"
          :isWithLink="true"
          btnName="Добавить видео"
          nameMaxLength="100"
          :isDisableBtn="isLimitedVideoLength"
          @add="addVideos"
          @delete="deleteVideos"
        />
        <hr />
        <h3 class="mb-27">Полезные материалы</h3>
        <ActionItems
          :actions="card.stepMaterials"
          columnName="Название"
          :isWithLink="true"
          btnName="Добавить"
          nameMaxLength="80"
          @add="addMaterials"
          @delete="deleteMaterials"
        />

        <hr />
      </div>
      <div class="mb-27">
        <h3 class="mb-27">Дополнительная информация</h3>
        <TinyMceEditor placeholder="Введите текст" v-model="card.extraInfo" :init="tinyOptions" />
      </div>
      <div class="form-group">
        <p class="label-field">Для чего нужен навигатор</p>
        <ComponentInput placeholder="Введите" v-model="card.purpose" mod="rectangular" />
      </div>
      <div class="card-added-inf-row">
        <div class="form-group">
          <p class="label-field">Наименование процедуры</p>
          <ComponentInput placeholder="Введите" v-model="card.procedureName" mod="rectangular" />
        </div>
        <div class="form-group">
          <p class="label-field">Последовательность</p>
          <ComponentInput placeholder="Введите" v-model="card.sequence" mod="rectangular" />
        </div>
      </div>
      <div class="card-added-inf-row">
        <div class="form-group">
          <p class="label-field">Предшествующая операция</p>
          <ComponentInput placeholder="Введите" v-model="card.previousStep" mod="rectangular" />
        </div>
        <div class="form-group">
          <p class="label-field">Последующая операция</p>
          <ComponentInput placeholder="Введите" v-model="card.nextStep" mod="rectangular" />
        </div>
      </div>
      <div class="form-group">
        <p class="label-field">Список связанных шагов</p>
        <ComponentInput placeholder="Введите" v-model="card.relatedSteps" type="textarea" mod="rectangular" />
      </div>
      <div class="row mb-32 mt-32">
        <div class="checkboxInput">
          <input type="checkbox" v-model="card.isActive" id="chActive" />
          <label for="chActive">Запись активна</label>
        </div>
      </div>
    </div>
    <div class="mb-16">
      <ButtonStock class="mr-8" title="Сохранить" @click="save" />
      <button class="card-cancel-btn" @click="close">Отменить</button>
    </div>
  </div>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex';
  import { useVuelidate } from '@vuelidate/core';
  import { cloneDeep } from 'lodash';

  import ComponentInput from '@/common/components/ComponentInput';
  import Constants from '@/common/constants';

  import ButtonStock from '@/components/buttons/ButtonStock';
  import TinyMceEditor from '@/components/tinymceeditor';

  import ActionItems from '../components/operations/ActionItems.vue';
  import CardListItems from '../components/operations/CardListItems.vue';
  import DocumentItem from '../components/operations/DocumentItem.vue';
  import TimeAndCostForm from '../components/operations/TimeAndCostForm.vue';
  import { NAVIGATOR_STEP_CARD_ACTIONS_NAME, NAVIGATOR_STEP_CARD_GETTERS_NAME } from '../store/stepCards';
  import { OrganizationRoleId, TINY_OPTIONS } from '../utils/constants';

  export default {
    name: 'StepCard',
    components: {
      ButtonStock,
      ComponentInput,
      TinyMceEditor,
      CardListItems,
      DocumentItem,
      ActionItems,
      TimeAndCostForm,
    },
    data() {
      return {
        cardId: this.$route.params.id,
        card: {
          stepParticipants: [],
          stepActions: [],
          isTemplate: true,
          stepResultDocumentIds: [],
          stepRequiredDocumentIds: [],
          stepLegalSourceIds: [],
          stepVideos: [],
          stepMaterials: [],
        },
        regulators: [],
        participants: [],

        hours: null,
        minutes: null,

        tinyOptions: TINY_OPTIONS,
        organizationRoleId: OrganizationRoleId,

        regulatorsDuplicates: false,
        participantDuplicates: false,
        resultDocumentDuplicates: false,
        requiredDocumentDuplicates: false,
        legalSourceDuplicates: false,
      };
    },
    created() {
      this.loadDictionaries();

      this.loadCard();
    },
    computed: {
      ...mapGetters({
        getCard: NAVIGATOR_STEP_CARD_GETTERS_NAME.getCard,
        getListOrganizations: NAVIGATOR_STEP_CARD_GETTERS_NAME.getListOrganizations,
        getListDocuments: NAVIGATOR_STEP_CARD_GETTERS_NAME.getListDocuments,
        getListLegalSources: NAVIGATOR_STEP_CARD_GETTERS_NAME.getListLegalSources,
      }),

      isLimitedVideoLength() {
        return this.card.stepVideos.length === 6;
      },

      getDuplicates() {
        return (
          this.regulatorsDuplicates ||
          this.participantDuplicates ||
          this.resultDocumentDuplicates ||
          this.requiredDocumentDuplicates ||
          this.legalSourceDuplicates
        );
      },
    },
    methods: {
      ...mapActions({
        getStepCard: NAVIGATOR_STEP_CARD_ACTIONS_NAME.getStepCard,
        getOrganizationList: NAVIGATOR_STEP_CARD_ACTIONS_NAME.getOrganizationList,
        getDocumentList: NAVIGATOR_STEP_CARD_ACTIONS_NAME.getDocumentList,
        getLegalSourceList: NAVIGATOR_STEP_CARD_ACTIONS_NAME.getLegalSourceList,
        updateStepCard: NAVIGATOR_STEP_CARD_ACTIONS_NAME.updateStepCard,
        createStepCard: NAVIGATOR_STEP_CARD_ACTIONS_NAME.createStepCard,
      }),
      async loadCard() {
        if (this.cardId > 0) {
          await this.getStepCard({ id: this.cardId });
          this.card = this.getCard;

          this.participants = this.card.stepParticipants
            .filter(({ organizationRoleId }) => organizationRoleId === this.organizationRoleId.PARTICIPANT)
            .sort((a, b) => b.orderNum - a.orderNum);
          this.regulators = this.card.stepParticipants
            .filter(({ organizationRoleId }) => organizationRoleId === this.organizationRoleId.REGULATOR)
            .sort((a, b) => b.orderNum - a.orderNum);

          this.card.stepResultDocumentIds = this.card.stepResultDocumentIds.map((id) => ({ id }));
          this.card.stepRequiredDocumentIds = this.card.stepRequiredDocumentIds.map((id) => ({ id }));
          this.card.stepLegalSourceIds = this.card.stepLegalSourceIds.map((id) => ({ id }));

          if (this.card.isHourFormat) {
            this.hours = this.card.durationMins / 60;
          } else {
            this.minutes = this.card.durationMins;
          }
        }
      },
      async loadDictionaries() {
        const request = {
          pageNumber: 1,
          pageSize: 0,
          typeIds: [Constants.organizationType.navigatorParticipant],
          isActive: true,
        };
        await this.getOrganizationList(request);

        await this.getDocumentList({ pageNumber: 1, pageSize: 0, isActive: true });

        await this.getLegalSourceList({ pageNumber: 1, pageSize: 0, isActive: true });
      },

      async save() {
        this.v$.$touch();
        if (this.v$.$invalid || this.getDuplicates) {
          Constants.alert.fire('Ошибка', Constants.validationError, 'error');
          return;
        }

        this.card.stepParticipants = [
          ...this.regulators.filter(({ organizationId }) => organizationId != null),
          ...this.participants.filter(({ organizationId }) => organizationId != null),
        ];

        this.card.stepResultDocumentIds = this.card.stepResultDocumentIds.filter((id) => id != null);
        this.card.stepRequiredDocumentIds = this.card.stepRequiredDocumentIds.filter((id) => id != null);
        this.card.stepLegalSourceIds = this.card.stepLegalSourceIds.filter((id) => id != null);
        this.card.stepActions = this.card.stepActions.filter(({ action }) => action !== '');
        this.card.stepVideos = this.card.stepVideos.filter(({ name, url }) => name !== '' || url !== '');
        this.card.stepMaterials = this.card.stepMaterials.filter(({ name, url }) => name !== '' || url !== '');

        this.card.durationMins = this.card.isHourFormat ? this.hours * 60 : this.minutes;

        const card = cloneDeep(this.card);

        card.stepResultDocumentIds = this.card.stepResultDocumentIds
          .filter(({ id }) => id != null)
          .map(({ id }) => Number(id));
        card.stepRequiredDocumentIds = this.card.stepRequiredDocumentIds
          .filter(({ id }) => id != null)
          .map(({ id }) => Number(id));
        card.stepLegalSourceIds = this.card.stepLegalSourceIds
          .filter(({ id }) => id != null)
          .map(({ id }) => Number(id));
        card.cardId = this.cardId;

        const res = this.cardId > 0 ? await this.updateStepCard(card) : await this.createStepCard(card);
        if (res && res.success) {
          Constants.alert.fire('Сохранение', Constants.commonSuccess, 'success');
          if (!this.card.id) {
            this.$router.push({ name: 'Steps', params: { id: res.data.id, action: 'edit' } });
          }
        } else {
          Constants.alert.fire('Сохранение', Constants.commonError, 'error');
        }
      },
      addRegulators() {
        this.regulators.push({
          organizationId: null,
          organizationRoleId: this.organizationRoleId.REGULATOR,
          orderNum: null,
          stepId: Number(this.cardId),
        });
      },
      deleteRegulators(index) {
        this.regulators.splice(index, 1);
      },
      addParticipant() {
        this.participants.push({
          organizationId: null,
          organizationRoleId: this.organizationRoleId.PARTICIPANT,
          orderNum: null,
          stepId: Number(this.cardId),
        });
      },
      deleteParticipant(index) {
        this.participants.splice(index, 1);
      },
      addAction() {
        this.card.stepActions.push({
          orderNum: this.card.stepActions.length + 1,
          action: '',
        });
      },
      deleteAction(index) {
        this.card.stepActions.splice(index, 1);
      },
      addResultDocument() {
        this.card.stepResultDocumentIds.push({ id: null });
      },
      deleteResultDocument(index) {
        this.card.stepResultDocumentIds.splice(index, 1);
      },
      addRequiredDocument() {
        this.card.stepRequiredDocumentIds.push({ id: null });
      },
      deleteRequiredDocument(index) {
        this.card.stepRequiredDocumentIds.splice(index, 1);
      },
      addLegalSource() {
        this.card.stepLegalSourceIds.push({ id: null });
      },
      deleteLegalSource(index) {
        this.card.stepLegalSourceIds.splice(index, 1);
      },
      addVideos() {
        if (this.isLimitedVideoLength) {
          return;
        }
        this.card.stepVideos.push({ name: '', url: '' });
      },
      deleteVideos(index) {
        this.card.stepVideos.splice(index, 1);
      },
      addMaterials() {
        this.card.stepMaterials.push({ name: '', url: '' });
      },
      deleteMaterials(index) {
        this.card.stepMaterials.splice(index, 1);
      },
      close() {
        this.$router.push({ name: 'Steps' });
      },
      switchHandTime(value) {
        this.card.isHourFormat = value;
      },

      duplicatesRegulators(val) {
        this.regulatorsDuplicates = val;
      },
      duplicatesParticipant(val) {
        this.participantDuplicates = val;
      },
      duplicatesResultDocument(val) {
        this.resultDocumentDuplicates = val;
      },
      duplicatesRequiredDocument(val) {
        this.requiredDocumentDuplicates = val;
      },
      duplicatesLegalSource(val) {
        this.legalSourceDuplicates = val;
      },
    },
    setup: () => ({ v$: useVuelidate() }),
  };
</script>

<style lang="scss" scoped>
  @import '../styles/StepCard.scss';
</style>
